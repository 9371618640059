.csd-tag {
  @apply tw-h-12 xs:tw-h-11;
  @apply tw-rounded-lg tw-bg-input tw-justify-center;
  @apply tw-text-base tw-font-medium tw-leading-5 xs:tw-text-sm;
  @apply tw-grid tw-items-center tw-px-3 tw-relative;

  &:has(.csd-button_icon) {
    @apply xs:tw-justify-start tw-pr-[52px];
  }

  .csd-button_icon {
    @apply tw-absolute tw-right-0 tw-top-0;
  }

  &-container {
    @apply tw-grid tw-gap-2.5;
    &_disabled {
      @apply tw-pointer-events-none tw-opacity-50;
    }
  }

  &-label {
    @apply tw-text-base tw-leading-5 xs:tw-text-sm xs:tw-leading-4 tw-font-medium;
  }

  &__placeholder {
    @apply tw-text-input-placeholder;
  }
}
