.csd-button {
  @apply tw-flex tw-justify-center tw-items-center tw-gap-1.5;
  @apply tw-h-12 tw-w-full tw-rounded-lg tw-px-2.5;
  @apply tw-text-base tw-leading-5 tw-font-semibold;
  @apply disabled:tw-opacity-50;
  transition: 0.3s;

  mat-icon {
    @apply tw-font-medium tw-text-lg;
  }
}

@mixin ButtonStroked() {
  @extend .csd-button;
  @apply tw-border tw-border-solid;
}

.csd-button_flat {
  @extend .csd-button;

  &[color="primary"] {
    @apply tw-bg-primary tw-text-white;
    background-color: var(--primary-color);
  }

  &[color="warn"] {
    @apply tw-bg-error tw-text-white;
  }

  &[color="success"] {
    @apply tw-bg-success tw-text-white;
  }

  &:not(:disabled) {
    @apply hover:tw-brightness-105;
  }
}

.csd-button_stroked {
  @extend .csd-button;
  @apply tw-border tw-border-solid;

  &[color="secondary"] {
    @apply tw-border-secondary tw-text-secondary;
    &:not(:disabled) {
      @apply hover:tw-border-black hover:tw-text-black;
    }
  }

  &[color="primary"] {
    @apply tw-border-primary tw-text-primary;
    border-color: var(--primary-color);
    color: var(--primary-color);

    &:not(:disabled):hover {
      @apply tw-bg-primary tw-text-white;
      background-color: var(--primary-color);
    }

    .mat-mdc-progress-spinner
      .mdc-circular-progress__indeterminate-circle-graphic {
      @apply tw-stroke-primary;
      stroke: var(--primary-color);
    }
  }

  &[color="warn"] {
    @apply tw-border-error tw-text-error;
    &:not(:disabled):hover {
      @apply tw-bg-error-hover tw-text-white;
    }

    .mat-mdc-progress-spinner
      .mdc-circular-progress__indeterminate-circle-graphic {
      @apply tw-stroke-error;
    }
  }
}

.csd-button_icon {
  @extend .csd-button;
  @extend .csd-button_stroked;
  @apply tw-h-12 tw-w-12 xs:tw-h-11 xs:tw-w-11;
  mat-icon {
    @apply tw-text-xl tw-leading-none xs:tw-text-lg;
  }
  border-width: 2px;
}
