@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./styles/reset";
@import "./styles/material/theme";

@import "./styles/global";

// components
@import "./styles/components/buttons";
@import "./styles/components/card";
@import "./styles/components/input";
@import "./styles/components/tag";
@import "./styles/components/skeleton-loading";
@import "./styles/components/scroll-bar";

body {
  @apply tw-bg-[#CEDEE5];
  --primary-color: #3880ec;
  @extend .csd-scroll;
}

html,
body,
csd-root {
  @apply tw-min-h-screen tw-grid tw-p-0 tw-m-0;
}

* {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
}

.row {
  @apply tw-grid tw-grid-flow-col tw-justify-between tw-items-center;
}

mat-progress-bar[for-block] {
  @apply tw-absolute tw-w-full tw-top-0 tw-left-0;
}
