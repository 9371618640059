[data-skeleton-load] {
  animation: skeleton-loading 1s linear infinite alternate;
}

[data-skeleton-load="text"] {
  @apply tw-rounded tw-w-full;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 90%);
  }
}
